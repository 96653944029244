import dayjs from 'dayjs';
import type {
  DateFilter,
  FilterModel,
} from '../components/Pageheader/Search/models';
import type { SearchModel } from '../stores/searchStore';

export default (
  formModel_fulltextsearch: MaybeRefOrGetter<string>,
  formModel_date: MaybeRefOrGetter<DateFilter>,
  formModel_filter: MaybeRefOrGetter<FilterModel>
): Ref<SearchModel> => {
  return computed(() => {
    const formModel_filterValue = toValue(formModel_filter);
    const formModel_dateValue = toValue(formModel_date);

    const formObject: Partial<Record<keyof SearchModel, unknown>> = {
      search: toValue(formModel_fulltextsearch),
      categories: formModel_filterValue.categories
        ?.filter((category) => category && category.id && category.selected)
        .map((category) => category.id!),
      criteria: formModel_filterValue.attributes
        ?.filter((attribute) => attribute && attribute.id && attribute.selected)
        .map((attribute) => attribute.id!),
      dateFrom:
        formModel_dateValue.date.selectedDateRange[0] ||
        formModel_dateValue.date.pendingDate,
      dateTo:
        formModel_dateValue.date.selectedDateRange[1] ||
        formModel_dateValue.date.pendingDate,
      daytime: formModel_dateValue.additional.dayTime,
      onlyFree:
        formModel_dateValue.additional.categories.includes('free-of-charge'),
      hasSingleEvent:
        formModel_dateValue.additional.categories.includes('single'),
      duration: formModel_filterValue.tour?.duration,
      length: formModel_filterValue.tour?.length,
      difficulties: formModel_filterValue.tour?.difficulties,
      locationId: formModel_filterValue.location.location?.id,
      locationType: formModel_filterValue.location.location?.type,
      locationName: formModel_filterValue.location.location?.name,
      latitude: formModel_filterValue.location.location?.latitude,
      longitude: formModel_filterValue.location.location?.longitude,
    };

    const parsedState: SearchModel = {} as SearchModel;

    Object.keys(formObject).forEach((key) => {
      const typedKey = key as keyof SearchModel;
      const value = toValue(formObject[typedKey]);
      switch (typedKey) {
        case 'search':
          parsedState.search = !isEmpty(value)
            ? (value as string).split(' ')
            : [];
          break;
        case 'dateFrom':
        case 'dateTo':
          parsedState[typedKey] = !isEmpty(value)
            ? dayjs(value as string).format('YYYY-MM-DD')
            : undefined;
          break;
        case 'daytime':
          if (Array.isArray(value)) {
            const validDaytimes = ['morning', 'afternoon', 'evening'];
            parsedState.daytime = (value as string[])
              .filter((time) => validDaytimes.includes(time))
              .sort(
                (a, b) => validDaytimes.indexOf(a) - validDaytimes.indexOf(b)
              );
          } else {
            parsedState.daytime = [];
          }
          break;
        case 'latitude':
        case 'longitude':
        case 'radius':
        case 'locationId':
          parsedState[typedKey] =
            isEmpty(value) || isNaN(Number(value)) ? undefined : Number(value);
          break;
        case 'onlyFree':
        case 'hasSingleEvent':
          parsedState[typedKey] = value === true ? true : undefined;
          break;
        case 'duration':
        case 'length':
          parsedState[typedKey] = !isEmpty(value)
            ? (value as [string, string]).map(Number).every((num) => num === 0)
              ? undefined
              : ((value as [string, string]).map(Number) as [number, number])
            : undefined;
          break;
        case 'difficulties':
          parsedState[typedKey] = !isEmpty(value)
            ? (value as number[]).map(Number)
            : [];
          break;
        default:
          (parsedState[typedKey] as unknown) = value;
      }
    });

    return parsedState;
  });
};
