<template>
  <div v-if="items?.length > 0" class="categories">
    <strong v-if="label" class="heading">{{ label }}</strong>

    <div class="list">
      <InputCheckbox
        v-for="item in items"
        :key="item"
        v-model="model"
        :label="item.label"
        :name="name"
        :value="item.value"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
const model = defineModel<string[]>({ default: [] });

defineProps<{
  label?: string;
  name?: string;
  items?: Array<{ label: string; value: string }>;
}>();
</script>

<style src="./Categories.scss" scoped lang="scss"></style>
